import React from "react";
import {useState} from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import groupBy from "lodash/groupBy";
import pickBy from "lodash/pickBy";

import {withProvider} from "graphqls/graphqlProvider";
import Book from "./Book";
import Tab from "components/Common/Tab";

const booksQuery = gql`
  query allBooks {
    books {
      id
      testament
      title
      abbr
      number
      createdAt
      chaptersCount
      chapters {
        id
        number
      }
    }
  }
`;

const BookList = () => {
  const {data, loading, error} = useQuery(booksQuery);
  const [activeTab, setActiveTab] = useState("old_testament"); // 구약

  if (loading) return "Loading...";
  if (error) return `Error ${error.message}`;

  const groupByTestament = groupBy(data.books, (book) => book.testament);
  const pickByGroup = pickBy(groupByTestament, (v, k) => k == activeTab);
  const tabs = [
    { id: "old_testament", name: "구약", href: "#old", count: groupByTestament.old_testament.length, current: true },
    { id: "new_testament", name: "신약", href: "#new", count: groupByTestament.new_testament.length, current: false },
  ];

  return (
    <div>
      <Tab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

      <div className="py-6">
        {Object.entries((pickByGroup)).map(([testament, books]) => (
          <div key={testament} className="mb-6">
            <ul className="grid grid-cols-2 gap-5 sm:gap-6 sm:grid-cols-3 lg:grid-cols-5">
              {books.map((book) => (
                <Book {...book} key={book.id} />
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withProvider(BookList);

// How can I update the parent's state in React?
// https://stackoverflow.com/questions/35537229/how-can-i-update-the-parents-state-in-react
//
// This is how we can do it with the new useState hook.
// Method - Pass the state changer function as a props to the child component and do whatever you want to do with the function
//
// import React, {useState} from 'react';

// const ParentComponent = () => {
//   const[state, setState]=useState('');
  
//   return(
//     <ChildConmponent stateChanger={setState} />
//   )
// }


// const ChildConmponent = ({stateChanger, ...rest}) => {
//   return(
//     <button onClick={() => stateChanger('New data')}></button>
//   )
// }
