import React from "react";

import ResultItem from "./ResultItem";

const ResultList = ({results}) => {
  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-6">
          {results.length}건의 검색 결과입니다.
        </div>
      </div>

      {results.map((result) => (
        <ResultItem result={result} key={result.id} />
      ))}
    </div>
  );
};

export default ResultList;
