import React from "react";
import { Link } from "react-router-dom";

import BookList from "components/Books/BookList";

function Home() {
  return (
    <>
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">믿음을 어떻게 나누시나요?</span>
            <span className="block text-indigo-600">삶 속에서 사람들에게 하나님의 말씀을 전하십시오.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/books"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Get started
              </Link>
            </div>

            <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-10">
          {/* <div className="pb-5 border-b border-gray-200"> */}
          {/*   <h3 className="text-lg leading-6 font-medium text-gray-900">성경</h3> */}
          {/* </div> */}

          <BookList />
        </div>
      </div>
    </>
  );
}

export default Home;
