import React, { useState, useContext } from "react";

const user = JSON.parse(localStorage.getItem("user"));

const defaultValue = {
  currentUser: user,
  setCurrentUser: () => {},
};

console.debug(defaultValue);

const UserContext = React.createContext(defaultValue);

const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(user);
  const value = {
    currentUser: currentUser,
    setCurrentUser: setCurrentUser,
  };

  return (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  );
};

//const UserConsumer = UserContext.Consumer와 같은 의미
const { Consumer: UserConsumer } = UserContext;

export { UserProvider, UserConsumer};
export const useUserContext = () => useContext(UserContext);
export default UserContext;
