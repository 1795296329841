import React from "react";
import { Link } from "react-router-dom";

const Book = (book) => {
  return (
    <li className="col-span-1 flex shadow-sm rounded-md">
      <div className='bg-purple-600 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'>
        {book.number}
      </div>
      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
        <div className="flex-1 px-4 py-2 text-sm truncate">
          <Link to={`/books/${book.id}`} className="text-gray-900 font-medium hover:text-gray-600">
            {book.title}
          </Link>
          <p className="text-gray-500">총 {book.chaptersCount}장</p>
        </div>
      </div>
    </li>
  );
};

export default Book;
