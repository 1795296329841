import React, { useState, useContext } from "react";

import { getAuthToken } from "utils/auth";

const defaultValue = {
  isSignedIn: getAuthToken(),
  setSignedIn: () => { },
};

console.debug(defaultValue);

const AuthContext = React.createContext(defaultValue);

const AuthProvider = ({ children }) => {
  const [isSignedIn, setSignedIn] = useState(getAuthToken());
  const value = {
    isSignedIn: isSignedIn,
    setSignedIn: setSignedIn,
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};

//const AuthConsumer = AuthContext.Consumer와 같은 의미
const { Consumer: AuthConsumer } = AuthContext;

export { AuthProvider, AuthConsumer};
export const useAuthContext = () => useContext(AuthContext);
export default AuthContext;
