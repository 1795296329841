import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";

import { getCSRFToken } from "utils/auth";

const client = new ApolloClient({
  link: new HttpLink({
    // uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    uri: "/graphql",
    credentials: "include", // httponly cookie
    headers: {
      "X-CSRF-Token": getCSRFToken(),
      // "Authorization": getAuthToken(),
    },
  }),
  cache: new InMemoryCache(),
});

// const client = new ApolloClient({
//   uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
//   cache: new InMemoryCache(),
// });

export const withProvider = (
  WrappedComponent,
  props,
) => () => {
  return (
    <ApolloProvider client={client}>
      <WrappedComponent {...props} />
    </ApolloProvider>
  );
};
