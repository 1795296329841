import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

import {withProvider} from "graphqls/graphqlProvider";
import ResultList from "components/Results/ResultList";

const chapterVersesQuery = gql`
  query chapterVerses($cid: ID!) {
    verses(cid: $cid) {
      id
      number
      text
      chapter {
        number
      }
      book {
        title
      }
    }
  }
`;

const Results = () => {
  const { loading, error, data } = useQuery(chapterVersesQuery, {
    variables: { cid: 1 }
  });

  if (loading) return "Loading...";
  if (error) return `Error ${error.message}`;

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-10">
          <ResultList results={data.verses}/>
        </div>
      </div>
    </>
  );
};

export default withProvider(Results);
