import { React, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import Gravatar from "react-gravatar";
import classNames from "classnames";

import axios from "apis/axios";
import { useAuthContext } from "contexts/auth";
import { useUserContext } from "contexts/user";

const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
];

const ProfileMenu = () => {
  const history = useHistory();
  const {isSignedIn, setSignedIn} = useAuthContext();
  const {currentUser, setCurrentUser} = useUserContext();

  const handleSignout = (e) => {
    e.preventDefault();

    axios.delete("/users/sign_out")
      .then(response => {
        // handle success
        console.log(response);

        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setSignedIn(null);
        setCurrentUser(null);
        history.push("/");
      })
      .catch(error => {
        // handle error
        console.log(error);
      })
      .then(() => {
        // always executed
      });
  };

  return (
    <Menu as="div" className="flex-shrink-0 relative ml-5">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span className="sr-only">Open user menu</span>
              <Gravatar email={currentUser.email} className="h-8 w-8 rounded-full" alt="" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block py-2 px-4 text-sm text-gray-700"
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}

              <Menu.Item key="signout">
                {({ active }) => (
                  <a
                    onClick={handleSignout}
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block py-2 px-4 text-sm text-gray-700"
                    )}
                  >
                    로그아웃
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default ProfileMenu;
