import React from "react";
import { useHistory } from "react-router-dom";

import { useAuthContext } from "contexts/auth";

export default function VerseItem({verse, modalOpen, setModalOpen}) {
  const history = useHistory();
  const {isSignedIn} = useAuthContext();

  const onClick = (e, verse) => {
    e.preventDefault();

    if (!isSignedIn) {
      setModalOpen(true);
      // history.push("/signin");
    }
  };

  return (
    <span
      className="mr-2 hover:text-indigo-500"
      onClick={(e) => onClick(e, verse) }
    >
      <sup>{verse.number}</sup>
      <span>{verse.text}</span>
    </span>
  );
}
