import React from "react";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import {useQuery} from "@apollo/client";

import {withProvider} from "graphqls/graphqlProvider";
import ChapterItem from "components/Chapters/ChapterItem";

const bookQuery = gql`
  query Book($id: ID!) {
    book(id: $id) {
      id
      title
      chapters {
        id
        number
        versesCount
      }
    }
  }
`;

const Book = () => {
  const params = useParams();
  console.log(params);

  const { loading, error, data } = useQuery(bookQuery, {
    variables: { id: params.id }
  });

  if (loading) return "Loading...";
  if (error) return `Error ${error.message}`;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-10">
        <div className="pb-5 border-b border-gray-200">
          <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
            <h3 className="ml-2 mt-2 text-lg leading-6 font-medium text-gray-900">
              {data.book.title}
            </h3>
            <p className="ml-2 mt-1 text-sm text-gray-500 truncate">
              {data.book.chapters.length}
            </p>
          </div>
        </div>

        <ul className="mt-6 grid grid-cols-2 gap-5 sm:gap-6 sm:grid-cols-4 lg:grid-cols-6">
          {data.book.chapters.map((chapter) => (
            <ChapterItem {...chapter} key={chapter.id} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withProvider(Book);
