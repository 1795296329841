import React from "react";
// import gql from "graphql-tag";
// import {useQuery} from "@apollo/client";

import BookList from "components/Books/BookList";

const Books = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-10">
          <BookList />
        </div>
      </div>
    </>
  );
};

export default Books;
