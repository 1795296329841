import React from "react";
import classNames from "classnames";

const Tab = ({ tabs, activeTab, setActiveTab }) => {
  const onClickTab = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab.id);
  };

  return (
    <div>
      <div className="pb-5 border-b border-gray-200 sm:pb-0">
        <div className="mt-3 sm:mt-4">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames("whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm",
                    tab.id == activeTab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  )}
                  aria-current={tab.id == activeTab ? "page" : null}
                  onClick={(e) => onClickTab(e, tab)}
                >
                  {tab.name}
                  {tab.count ? (
                    <span
                      className={classNames("hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block",
                        tab.id == activeTab ? "bg-indigo-100 text-indigo-600" : "bg-gray-100 text-gray-900"
                      )}
                    >
                      {tab.count}
                    </span>
                  ) : null}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
