import React from "react";
import { useParams } from "react-router-dom";
import {gql, useQuery} from "@apollo/client";

import {withProvider} from "graphqls/graphqlProvider";
import VerseList from "components/Verses/VerseList";

const CHAPTER_VERSES_QUERY = gql`
  query ChapterVerses($cid: ID!) {
    verses(cid: $cid) {
      id
      number
      text
      chapter {
        number
      }
      book {
        title
      }
    }
  }
`;

const ChapterPage = () => {
  const params = useParams();
  console.debug(params);

  const { loading, error, data } = useQuery(CHAPTER_VERSES_QUERY, {
    variables: { cid: params.id }
  });

  if (loading) return "Loading...";
  if (error) return `Error ${error.message}`;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-10">
        <VerseList verses={data.verses} />
      </div>
    </div>
  );
};

export default withProvider(ChapterPage);
