import axios from "axios";
// import { getAuthToken, getCSRFToken } from "utils/auth";

// axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.baseURL = "/api/";
axios.defaults.timeout = 1000;
axios.defaults.xsrfCookieName = "CSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.withCredentials = true; // httponly cookie
// axios.defaults.headers.common["X-CSRF-Token"] = getCSRFToken();

const requestHandler = request => {
  // request.headers["Authorization"] = getAuthHeader();

  return request;
};

axios.interceptors.request.use(request => requestHandler(request));

// Provide only response json part
// => Chuk other metadata provided by axios
const responseSuccessHandler = response => {
  return response.data;
};

// Log & Sanitize errors response
// => The errors given by server will not be always consistent so we
//    could sanitize the response and return proper error to the client.
const responseErrorHandler = error => {
  var errors = ["Something went wrong, please try again!"];

  if (error.response) {
    if (error.response.data.errors)
      errors = error.response.data.errors;
    if (error.response.data.error)
      errors = [error.response.data.error];

    if (error.response.status === 401)
      console.log(error);
      // forceLogoutUser();
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }

  return Promise.reject({
    status: error.response.status,
    errors: errors
  });
};

axios.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);

export default axios;
