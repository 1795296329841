import React from "react";

export default function ResultItem({result}) {
  return (
    <div className="mb-6">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative py-6 px-4 bg-indigo-500 rounded-xl shadow-2xl overflow-hidden lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
          </div>
          <div className="relative lg:col-span-1">
            <blockquote className="text-white font-serif">
              <p className="text-xl font-medium sm:text-2xl">
                {result.text}
              </p>
              <footer className="mt-6">
                <p className="flex flex-col font-medium">
                  {/* <span>Marie Chilvers</span> */}
                  {/* <span>CEO, Workcation</span> */}
                  <span>{result.book.title}, {result.chapter.number}장 {result.number}절</span>
                  {/* <span>{result.chapter.number}장 {result.number}절</span> */}
                  {/* <span>{result.book.title}</span> */}
                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
}
