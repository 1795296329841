import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import {withProvider} from "graphqls/graphqlProvider";

import "./App.css";
import Navbar from "components/Navbar/Navbar";
import Notification from "components/Common/Notification";
import Home from "./pages/Home";
import Books from "./pages/Books";
import Book from "./pages/Book";
import Chapter from "./pages/Chapter";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import Results from "./pages/Results";
import { AuthProvider } from "contexts/auth";
import { UserProvider } from "contexts/user";
import axios from "apis/axios";
import { trackVisit } from "utils/analytics";

const ME = gql`
  query Me {
    me {
      id
      name
      email
    }
  }
`;

function App() {
  trackVisit("My second event", {language: "JavaScript"});

  const {data, loading, error} = useQuery(ME);
  if (loading) return "Loading...";
  if (error) return `Error ${error.message}`;
  console.log(data);

  axios.get("/v1/me")
    .then(response => {
      // handle success
      console.log(response);
    })
    .catch(error => {
      // handle error
      console.log(error);
    })
    .then(() => {
      // always executed
    });

  // useEffect(() => {
  // });

  return (
    <AuthProvider>
      <UserProvider>
        <div className="App">
          <Navbar />
          <Notification />

          <Route path="/" exact={true} component={Home} />
          <Route path="/books" exact={true} component={Books} />
          <Route path="/books/:id" component={Book} />
          <Route path="/chapters/:id" component={Chapter} />
          <Route path="/signup" component={Signup} />
          <Route path="/signin" component={Signin} />
          <Route path="/results" component={Results} />
        </div>
      </UserProvider>
    </AuthProvider>
  );
}

export default withProvider(App);
