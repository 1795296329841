import ahoy from "ahoy.js";

ahoy.configure({
  urlPrefix: process.env.REACT_APP_HOST,
  visitsUrl: "/ahoy/visits",
  eventsUrl: "/ahoy/events",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: true,
  cookieDomain: null,
  headers: {},
  visitParams: {},
  withCredentials: false,
  visitDuration: 4 * 60, // 4 hours
  visitorDuration: 2 * 365 * 24 * 60 // 2 years
});

const trackVisit = (eventName, eventProperties) => {
  ahoy.track(eventName, eventProperties);
};

export { trackVisit };
export default ahoy;
