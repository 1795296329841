import React, { useState } from "react";

import Modal from "../Common/Modal";
import VerseItem from "./VerseItem";

const VerseList = ({verses}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <Modal open={modalOpen} setOpen={setModalOpen} />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mt-6 prose prose-indigo prose-lg mx-auto font-serif">
          <div className="mb-6">
            총 {verses.length}절
          </div>

            {verses.map((verse) => (
              <VerseItem verse={verse} key={verse.id} modalOpen={modalOpen} setModalOpen={setModalOpen} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default VerseList;
